export default defineAppConfig({
  ui: {
    primary: 'explorial',
    accordion: {
      item: {
        base: '',
        size: 'text-sm',
        color: '',
        padding: '',
        icon: 'ms-auto transform transition-transform duration-200',
      },
    }
  }
})